
import {createRouter,createWebHashHistory} from'vue-router'
import store from '../store/index'

const routes = [
  {
    path:'/',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: "about" */ '../views/PagInicial.vue')
  
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( '../views/auth/Login.vue')
  },
  {
    path: '/calendly',
    name: 'Calendly',
    component: () => import( '../views/Calendly.vue')
  },
  {
    path: '/welcome',
    name:'welcome',
    component: () => import(/* webpackChunkName: "about" */ '../views/Bienvenida/Welcome.vue'),
    //meta: {requireAuth: true}
  },
  {
    path: '/side',
    name: 'side',
    component: () => import( '../views/side.vue'),
    //meta: {requireAuth: true},
    children:[
      {
        path: "/iframe1",
        name: "iframe1",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/Iframe1.vue"),
      },
      {
        path: '/usuario',
        name: 'Usuarios',
        component: () => import( '../views/auth/Usuarios.vue')
      },
      {
        path: "/iframe2",
        name: "Iframe2",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/menus/Iframe2.vue"),
      },
      {
        path: "/descriptiva",
        name: "Descriptiva",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/Descriptiva.vue"),
      },
      {
        path: "/predictiva",
        name: "Predictiva",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/Predictiva.vue"),
      },
      {
        path: "/datastudio-p2",
        name: "datastudio-p2",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/datastudio-p2.vue"),
      },
      {
        path: "/oportunidades",
        name: "Oportunidades",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/Oportunidades.vue"),
      },
      {
        path: "/administracion",
        name: "Administracion",
        component: () => import(/* webpackChunkName: "about" */ "../views/menus/Administracion.vue"),
        children:[
          
        ]
      },
      {
        path: "/archivos",
        name: "Archivos",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Archivos.vue"),
      },
      
      {
        path: "/tableros-bi",
        name: "Tableros BI",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/menus/TablerosDinamicos.vue"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
      },
      {
        path: "/categorias",
        name: "Categorias",
        props: true,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/menus/CategoriasViews.vue"),
      },
      {
        path: "/new-categoria",
        name: "NewCategoria",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/menus/NewCategoria"),
      }
    ]
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to,from,next)=>{
  const rutaprotegida = to.matched.some(record => record.meta.requireAuth)
  //console.log(store.state.token)
  //console.log(rutaprotegida)
  if (rutaprotegida && store.state.token === ''){
      next({name: 'Login'})
  }
  else
  {
    next()
  }

})

export default router

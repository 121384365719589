export default {
  "pg": {
    "s1txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieve more in your company with us as your allies and take advantage of the capabilities that Business Intelligence brings to your company."])},
    "s2": {
      "f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controls indicators"])},
      "f2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increases productivity"])},
      "f3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduces operating costs"])},
      "f4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make decisions based on data"])}
    },
    "s3txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a platform that helps companies make decisions based on their data."])},
    "s4": {
      "txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a strategic support solution for the decisions of each business process"])},
      "txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millions of data are generated every day that can be used to interpret what is happening and to know how to move forward."])},
      "txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solve reporting and analytics management in a simple way and with experts in Business Intelligence who will be in charge of taking your company to another level"])}
    },
    "s5": {
      "tx1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized with your brand and colors"])},
      "tx2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management, customized reports"])},
      "tx3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicators and targets by indicator"])},
      "tx4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully dynamic dashboards"])},
      "tx5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports with a strategic and tactical approach for decision making"])},
      "tx6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical and strategic support"])}
    },
    "SERVICIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
    "modal": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any additional questions?"])},
      "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])}
    }
  },
  "bienvenida": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome "])},
    "msn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To your business intelligence platform. Turn your data into useful information for decision making."])},
    "boton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's continue"])}
  },
  "informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to schedule a demo"])},
  "iniciarsesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "contraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "msnInicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to continue"])},
  "sidebar": {
    "inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "administracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "indicadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicators"])},
    "archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat File"])},
    "usuarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "tableros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Reportss"])},
    "categoriaN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category management"])},
    "descriptiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptive Analytics"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "detallado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed"])},
    "oportunidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities"])},
    "actividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "predictiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive Analytics"])},
    "recomendacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  }
}
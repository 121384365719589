<template>
  <router-view/>
</template>
<script>
export default {
  mounted() {
    var scriptTag = document.createElement("script");
      scriptTag.src="https://www.googletagmanager.com/gtag/js?id=G-QZGD4M31WL";
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-QZGD4M31WL');
  },
}
</script>
<style>
 @import url('assets/css/style.css');

</style>
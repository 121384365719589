import { createApp } from 'vue'
import { createStore } from 'vuex'
import decode from "jwt-decode";
import router from '../router/index'


const store = createStore({
  state(){
      return{
        token: "",
        nombreUsuario: "",
        email: "",
        idEmpresa: 0,
        isAdmin:false,
        isUsuario:false,
        idCategoria:0,
        IdEmpresa:0,
        infoCategoria:0
      }
    },

  mutations: {
    //obtiene datos del usuario decifrando los datos desde el token JWT
    obtenerUsuario(state, payload) {
      state.token = payload;
      if (payload != "") {
        //decifrando usuario oculto en el token
        let decifrado = decode(payload);
        // router.push({name:'Admin'})  siempre dirige a vista admin cuando se actualiza
        //console.log(state.usuario)
        state.nombreUsuario = decifrado.user.Nombre
        state.email = decifrado.user.Email
        //console.log(state.email)
        state.idEmpresa = decifrado.user.IdEmpresa
        //console.log(decifrado.user.Roles)

        let admin = decifrado.user.Roles.find(x => x.Role.Rol == 'Administrador')
        state.isAdmin = admin != null
        let usuario = decifrado.user.Roles.find(x => x.Role.Rol == 'Usuario')
        state.isUsuario = usuario != null

        //console.log(state.isAdmin)

        /* state.rol= decifrado.user */
      }

    },
  },
  actions: {
    guardarUsuario({ commit }, payload) {
      //guardamos token en local storage
      localStorage.setItem('token', payload);
      commit('obtenerUsuario', payload)
    },
    cerrarSesion({ commit }) {
      commit('obtenerUsuario', '');
      localStorage.removeItem('token')
      router.push('/login')
      //this.state.nombreUsuario = ""
    },
    leerToken({ commit }) {
      const token = localStorage.getItem('token')
      if (token) {
        commit('obtenerUsuario', token)
      } else {
        commit('obtenerUsuario', '')
      }
    },
    
  },
  getters: {
    logueado: state => !!state.token,
  }
});

const app = createApp({ /* your root component */ })

// Install the store instance as a plugin
app.use(store)


export default store;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import axios from 'axios'
import VueAxios from 'vue-axios';
import VueGoodTablePlugin from 'vue-good-table-next';
import {VueCsvImportPlugin} from "vue-csv-import";
//cambio de idiomas 
import i18n from './i18n'


import 'vue-good-table-next/dist/vue-good-table-next.css'
const url = 'https://hylebi-backend.azurewebsites.net/api' 
//const url = 'https://hylebi.com/api'
//const url = 'http://localhost:4000/api'

const Vue = createApp(App)

Vue.use(VueAxios, axios)
//createApp(App).use(axios)
Vue.use(VueGoodTablePlugin)
Vue.use(VueCsvImportPlugin) 

axios.defaults.baseURL = url

Vue.use(i18n)
Vue.use(store)
Vue.use(router).mount('#app')

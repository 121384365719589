export default {
  "pg": {
    "s1txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logra más en tu empresa con nosotros como tus aliados y toma ventaja de las capacidades que el Business Intelligence le aporta a tu compañía"])},
    "s2": {
      "f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controla los indicadores"])},
      "f2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incrementa la productividad"])},
      "f3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce costos operacionales"])},
      "f4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma decisiones basadas en los datos"])}
    },
    "s3txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somos una plataforma que ayuda a las empresas a tomar decisiones basadas en sus datos."])},
    "s4": {
      "txt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es una solución de acompañamiento estratégico para las decisiones de cada proceso de negocio"])},
      "txt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día a día se generan millones de datos que pueden ser aprovechados para interpretar lo que ocurre y saber cómo movernos"])},
      "txt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resuelve la gestión de reporting y analítica de una manera sencilla y con expertos en Business Intelligence que se encargarán de llevar tu empresa a otro nivel"])}
    },
    "s5": {
      "tx1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado con tu marca y colores"])},
      "tx2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de usuarios, reportes personalizados"])},
      "tx3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicadores y metas por indicador"])},
      "tx4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards completamente dinámicos"])},
      "tx5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes con enfoque estratégico y táctico para la toma de decisiones"])},
      "tx6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompañamiento técnico y estratégico"])}
    },
    "SERVICIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICIOS"])},
    "modal": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
      "textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes alguna pregunta adicional?"])},
      "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agendar"])}
    }
  },
  "bienvenida": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "msn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tu plataforma de business intelligence. Convierte tus datos en información útil para la toma de decisiones."])},
    "boton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuemos"])}
  },
  "informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero agendar una demo"])},
  "iniciarsesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
  "contraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "msnInicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión para continuar"])},
  "sidebar": {
    "inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "administracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracion"])},
    "indicadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicadores"])},
    "archivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo Plano"])},
    "usuarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
    "tableros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes Personalizados"])},
    "categoriaN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión categorías"])},
    "descriptiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analítica Descriptiva"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercial"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "detallado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detallado"])},
    "oportunidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oportunidades"])},
    "actividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "predictiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analítica Predictiva"])},
    "recomendacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])}
  }
}